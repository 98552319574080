import React from 'react'
import ListTasks from '../components/ListTasks'

const ListPage: React.FC = () => {
  return (
    <>     
      <ListTasks />
    </>
  )
}

export default ListPage